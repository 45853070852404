body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}


* {
  box-sizing: border-box;
  font-family: 'DroidArabicNaskhRegular';
  line-height: 1.8;
}

.hindi ol {
  list-style-type:arabic-indic;
}

body {
  direction: rtl;
  text-align:right;
  font-size: 13px;
}

.switch {
  float: left;
  direction: ltr;
}

.settings ul {
  padding:0;
}

#info-panel .settings ul li {
  padding: 15px 10px;
}

.settings label {
  font-size: 130%;
}

.heading-image {
  width: 110%;
  height: 200px;
  margin-top: -5px;
  margin-right: -15px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.heading-image.generic {
  background-size: cover;
  background-position: center;
  background-image: url("/images/headings/generic.png") !important;
}

.heading-image.generic.state {
  background-image: url("/images/headings/state.png") !important;
}

.heading-image.generic.ruler {
  background-image: url("/images/headings/ruler.png") !important;
}

.heading-image.generic.monument {
  background-image: url("/images/headings/monument.png") !important;
}

.heading-image.generic.place {
  background-image: url("/images/headings/place.png") !important;
}

.heading-image.generic.event {
  background-image: url("/images/headings/event.png") !important;
}

.heading-image.generic.year {
  background-image: url("/images/headings/year.png") !important;
}

.heading-image.generic.scholar {
  background-image: url("/images/headings/scholar.jpg") !important;
}

.heading-image.with-map {
  background-image: none !important;
}

.heading-image h1 {
  background-color:rgba(0, 0, 125, 0.40);
  color: white;
  width: 100%;
  padding:5px 0;
  padding-left: 20px;
  font-size: 180%;
  text-align: left;
  margin:0;
  position: relative;
  top: 150px;
}

.heading-image h1.normal {
  font-size: 160%;
}

.heading-image h1.medium {
  font-size: 120%;
}

.heading-image h1.small {
  font-size: 100%;
}

ul.tags {
  display:flex;
  margin:0;
  padding: 5px 5px;
  margin-bottom: -10px;
}
ul.tags li{
	background-color:white;
	width:24px;
	height:24px;
  list-style: none;
	margin-right:2px;
	float:right;
	background-repeat: no-repeat;
	background-position:center center;
	border-radius:5px;
	border:2px solid #D6D27D !important ;
	margin-left: 6px;
	padding: 15px !important;
	background-size: 24px 24px;
}
ul.tags li:hover {
  background-color: #d6d27d !important;
}


li.astro {background-image : url('/images/pins/astro.png');}
li.math {background-image : url('/images/pins/math.png');}
li.medicine {background-image : url('/images/pins/medicine.png');}
li.physics {background-image : url('/images/pins/physics.png');}
li.chemistry {background-image : url('/images/pins/chemistry.png');}
li.law {background-image : url('/images/pins/law.png');}
li.biology {background-image : url('/images/pins/biology.png');}
li.philosophy {background-image : url('/images/pins/philosophy.png');}
li.music {background-image : url('/images/pins/music.png');}
li.history {background-image : url('/images/pins/history.png');}
li.geography {background-image : url('/images/pins/geography.png');}
li.lang {background-image : url('/images/pins/lang.png');}
li.trans {background-image : url('/images/pins/trans.png');}
li.hadith {background-image : url('/images/pins/hadith.png');}
li.tafseer {background-image : url('/images/pins/tafseer.png');}
li.geometry {background-image : url('/images/pins/geometry.png');}
li.lit {background-image : url('/images/pins/lit.png');}
li.soc {background-image : url('/images/pins/soc.png');}
li.eco {background-image : url('/images/pins/eco.png');}
li.mecha{background-image : url('/images/pins/mecha.png');}
li.pharma{background-image : url('/images/pins/pharma.png');}
li.botany{background-image : url('/images/pins/botany.png');}

#side-panel .heading-image h1 {
  background-color:rgba(0, 0, 125, 0.55);
  padding-left: 0px;
  padding-right: 20px;
  text-align:right;
  top: 130px;
  z-index: 5555;

}
.desc {
  text-align: right;
}
.read-more {
  color: #D6D27D;
  text-decoration: underline;
  cursor: pointer;
}

#global-overlay {
  position:fixed;
  width: 300px;
  height: 150px;
  border-width: 5px;
  right: 50%;
  top: 50%;
  margin-right: -150px;
  margin-top: -75px;
  background-color: black;
  z-index:999;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
}
#global-overlay .loading {
  background-image:url('/images/loading.gif');
  background-position: center center;
  background-repeat: no-repeat;
  width:100%;
  height:100%;
}

#global-overlay p {
  color:white;
  font-weight:bold;
}
a.internal-link {
  cursor: pointer;
}

#side-panel {
  position: fixed;
  height: 100%;
  top: 0;
  margin-left:-150px;
  z-index:99;
  transition-property: margin, right, left, width;
  transition-duration: 0s;
  color:white;
  box-shadow: 0px 0px 10px black;
  box-sizing: content-box;
  width:100%;
  overflow: hidden;
  left:100%;
}

#side-panel.invisible  {
  margin-right:-530px;
}
#side-panel.is-slid {
  margin-left:0px;
  right:0;
}

#menu-panel {
  float:left;
  width:50px;
  height:100%;
  background-color:black;
}

#info-panel {
  opacity:.88;
  height: 100%;
  z-index:3;
  transition-property: transform;
  transition-duration: .3s;
  background-color:#000;
  color:white;
  box-sizing: content-box;
  overflow: hidden;
  flex-grow: 1;
}

#side-panel h1.logo {
  display:none;
}

#slider-panel {
  margin-top:15px;
  text-align:center;
  flex-grow: 1;
  display:flex;
  flex-direction: column;
  position: relative;
  direction: ltr;
}

#timeline-panel {
  display:flex;
  flex-direction: column;
  float:left;
  padding: 15px 0px;
  width:100px;
  height:100%;
  border-right:1px solid #111111;
  opacity:.88;
  background-color: black;
}

.rc-slider-vertical {
  flex: 1 1 auto;
}


.rc-slider-handle,
.rc-slider-mark,
.rc-slider-step {
  direction: ltr;
}

.rc-slider-mark {
  font-size:90% !important;
}

.rc-slider-mark-text {
  -webkit-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
  direction: rtl;
  margin-bottom: -75% !important;
  white-space: nowrap;
}

.CustomScrollbar-trackVertical {
  width: 0px !important;
}

#year-panel {
  text-align: center;
  height: 70px;
  cursor: pointer;
}

#year-panel span {
  font-size: 85%;
}






body > h1.logo {
  background-image: url('/images/logo.png');
  position:fixed;
  width: 75px;
  height:75px;
  background-position: center center;
  background-size:contain;
  background-repeat: no-repeat;
  left: 5px;
  top: 5px;
  margin:0;
  z-index:10;
}




a.share {
  transition-property: transform;
  transition-duration: .3s;
  transform: translate3d(0, 0, 0);
  width: 28px;
  height:28px;
  border: 0;
  margin:0;
  display: block;
  float:right;
  margin-top:10px;
  background-size:contain;
}

a.share {
  margin-right:0px;
}

a.share:hover {
  transform: translate3d(0, -7px, 0);
}
a.share.google   {  background-image: url('/images/google.png');  }
a.share.facebook {  background-image: url('/images/facebook.png');  }
a.share.twitter  {  background-image: url('/images/twitter.png');  }
a.share.reddit   {  background-image: url('/images/reddit.png');  }

.side-menu {
  margin:0;
  padding:0;
  list-style: none;
  list-style-position: inside;
  position:relative;
  z-index:5;
}

.side-menu li {
  background-repeat:no-repeat;
  background-position:right 8px center;
  padding-right: 50px;
  padding-top: 15px;
  padding-bottom:5px;
  background-size:35px 35px;
  height: 50px;
  box-sizing: border-box;
  border-bottom:solid 1px rgba(255, 255, 255, 0.05);
  cursor: pointer;
  overflow: hidden;
  background-color:black;
}

.side-menu li:hover {
  background-color:#00a97a;
}
.is-slid .side-menu li:hover {
  background-color:transparent;
}


.side-menu li.close {
  background-image: url('/images/close-base.png');
  height:1px;
  opacity:0.0;
}
.is-slid .side-menu li.close {
  height:50px;
  opacity:1.0;
}
.side-menu li.help { background-image: url('/images/help-base.png');  }
.side-menu li.settings { background-image: url('/images/settings-base.png');  }
.side-menu li.all-states { background-image: url('/images/pharaoh-base.png');  }
.side-menu li.all-cities { background-image: url('/images/building-base.png');  }
.side-menu li.about { background-image: url('/images/about-base.png');  }
.side-menu li.restart { background-image: url('/images/restart-base.png');  }
.side-menu li.search { background-image: url('/images/search-base.png');  }
.side-menu li.right-arrow { background-image: url('/images/right-arrow-base.png');  }
.side-menu li.left-arrow { background-image: url('/images/left-arrow-base.png');  }

.is-slid .side-menu li.close:hover { background-image: url('/images/close.png');  }
.is-slid .side-menu li.settings:hover { background-image: url('/images/settings.png');  }
.is-slid .side-menu li.all-states:hover { background-image: url('/images/pharaoh.png');  }
.is-slid .side-menu li.all-cities:hover { background-image: url('/images/building.png');  }
.is-slid .side-menu li.help:hover { background-image: url('/images/help.png');  }
.is-slid .side-menu li.about:hover { background-image: url('/images/about.png');  }
.is-slid .side-menu li.restart:hover { background-image: url('/images/restart.png');  }
.is-slid .side-menu li.search:hover {  background-image: url('/images/search.png');  }
.is-slid .side-menu li.right-arrow:hover { background-image: url('/images/right-arrow.png');  }
.is-slid .side-menu li.left-arrow:hover {  background-image: url('/images/left-arrow.png');  }


.social-media-pane {
  position: absolute;
  bottom: 10px;
  width: 32px;
  left: 5px;
}






#info-panel .info-container {
  /*
  position: relative;
  */
  overflow: visible;
  height:99%;
  padding-left:0;
  padding-right:0;
}

#info-panel div.fetching {
  background-image:url('/images/loading.gif');
  background-position: center center;
  background-repeat: no-repeat;
  text-align:center;
  height: 50%;
}

#info-panel div.failed {
  text-align:center;
}

#info-panel div.failed button {
  color:black;
}

#info-panel  h3 {
  margin:0;
  font-size: 1.6em;
}

#info-panel  h3 a {
  display:block;
  margin-top:3px;
  font-size: 1.1em;
}
#info-panel p {
  text-align: right;
  padding: 0px 10px;
}
#info-panel a {
  color: #D6D27D;
  text-decoration: underline;
}

#info-panel h4 {
  padding-right: 10px;
}

#info-panel a:hover {
  color:silver;
  text-decoration: underline;
}

#info-panel li {
  border-bottom: 1px solid rgba(125, 125, 125, 0.21);
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}
#info-panel ol {
  list-style-position: inside;
  padding-right: 10px;
  font-size:90%
}
#info-panel li:hover {
  background-color: rgba(255, 255, 255, 0.05);
}


.navicon-button {
  display: inline-block;
  position: relative;
  padding: 2.0625rem 2.5rem;
  transition: 0.25s;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  opacity: .8;
}
.navicon-button .navicon:before, .navicon-button .navicon:after {
  transition: 0.25s;
}
.navicon-button:hover {
  transition: 0.5s;
  opacity: 1;
}
.navicon-button:hover .navicon:before, .navicon-button:hover .navicon:after {
  transition: 0.25s;
}
.navicon-button:hover .navicon:before {
  top: .825rem;
}
.navicon-button:hover .navicon:after {
  top: -.825rem;
}

.navicon {
  position: relative;
  width: 2.5em;
  height: .3125rem;
  background: #3D94F6;
  transition: 0.5s;
  border-radius: 2.5rem;
}
.navicon:before, .navicon:after {
  display: block;
  content: '';
  height: .3125rem;
  width: 2.5rem;
  background: #3D94F6;
  position: absolute;
  z-index: -1;
  transition: 0.5s 0.25s;
  border-radius: 1rem;
}
.navicon:before {
  top: .625rem;
}
.navicon:after {
  top: -.625rem;
}

.open:not(.steps) .navicon:before,
.open:not(.steps) .navicon:after {
  top: 0 !important;
}

.open .navicon:before,
.open .navicon:after {
  transition: 0.5s;
}

.open.larr .navicon:before, .open.larr .navicon:after {
  width: 1.5rem;
}

.open.larr .navicon:before {
  transform: rotate(35deg);
  transform-origin: right top;
}
.open.larr .navicon:after {
  transform: rotate(-35deg);
  transform-origin: right bottom;
}















span.rel {
  color: orange;
}
span.rel.authority.work_3.work {
  color: deeppink;
}
span.rel.work_10 {
  color: green;
}
span.rel.work_50 {
  color: #D0FF00;
}

.simplebar {
  overflow-y: scroll;
}
.simplebar.horizontal {
  overflow-x: scroll;
  overflow-y: hidden;
}


@media only screen and (min-width: 550px) {


  #menu-panel {
      opacity:.88;
      background-color: black;
      transition-property: width, right;
      transition-duration: .3s;
      width: 160px;
  }


  #side-panel h1.logo {
      display: block;
      background-image: url('/images/logo-white.png');
      cursor: pointer;
      width: 150px;
      height:150px;
      background-position: center center;
      background-repeat: no-repeat;
      transition-property: width, height, opacity, margin;
      transition-duration: .3s;
      margin:0;
  }


  #info-panel h1.logo {
      width:auto;
      height:100px;
      background-size:contain;
  }

  .social-media-pane {
      position: absolute;
      bottom: 10px;
      width:auto;
  }

  #side-panel {
      width:500px;
      margin-right:-240px;
      right:0;
      left:auto;
      transition-duration: .3s;
  }

  #side-panel.is-slid {
      margin-right:0px;
      right:0;
  }

  #side-panel.is-slid #menu-panel {
      width:50px;
  }

  #side-panel.is-slid #menu-panel .logo {
      height:0;
      opacity:0;
  }

  #side-panel.is-slid .social-media-pane {
      position: absolute;
      bottom: 10px;
      left: 5px;
      width:32px;
  }


  a.share {
      margin-right: 5px;
      margin-left: 5px;
  }
  #side-panel.is-slid #menu-panel {
      opacity: 1;
  }
  .side-menu li {
      transition-property: transform;
      transition-duration: .3s;
  }

  .side-menu li:hover {
      background-color:#00a97a;
  }


  .is-slid .side-menu li:hover {
      background-color: transparent;
  }


  .is-slid a.share {
      margin-right: 0px;
      margin-left: 0px;
  }
}

#map {
  width: 100%;
  height: 100%;
  position: fixed;
}


#side-panel #map {
  position: static;
  width: 98%;
  height: 200px;
  position: absolute;
  top: 0;
  right: 0;
}

.gallery ul {
  list-style: none;
  padding:0;
}

.gallery li {
  float:right;
  border:0;
  margin-left: 10px;
}

img.thumbnail {
  max-width: 80px;
  max-height: 80px;
}


.search-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: 15px;
}

#info-panel .search-item {
  border-right-width: 150px !important;
  padding-right: 10px;
}

#info-panel .search-item.ruler {border-right: 25px solid red !important}
#info-panel .search-item.state {border-right: 25px solid green !important}
#info-panel .search-item.event {border-right: 25px solid blue !important}
#info-panel .search-item.monument {border-right: 25px solid gold !important}
#info-panel .search-item.place {border-right: 25px solid teal !important}
#info-panel .search-item.scholar {border-right: 25px solid gold !important}

#info-panel .search-input:focus,
#info-panel .search-input {
  height: 35px;
  width: 100%;
  padding: 3px;
  color: black;
  display: block;
  margin-top:1px;
  border:1px solid gray;
  outline: none;
}
#info-panel .search {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#info-panel .search-error {
  flex-grow: 1;
  display: flex;
  margin-top: 90px;
}
#info-panel .search-error span {
  display: block;
  width: 100%;
  font-size: large;
  color: silver;
  align-self: center;
  text-align: center;
}
p.release {
  font-size: xx-small;
  text-align: left !important;
  margin-top: 50px;
  margin-bottom: 5px;
  color: gray;
}


.ReactModalPortal {
  direction: ltr;
}

.ril-toolbar {
  direction: rtl;
}

.ril-caption {
  direction: rtl;
}

.mapboxgl-ctrl-attrib.mapboxgl-compact::after {
  left: 0;
  right: auto;
}

.mapboxgl-ctrl-attrib.mapboxgl-compact {
  padding-right: 0;
  padding-left: 24px;
  margin: 0px 0px 0px 5px;
}

.play-store-button {
  position:fixed;
  left:100px;
  bottom: 3px;
  width: 132px;
  height: 46px;
}

.play-store-button img {
  width: 100%;
  height: 100%;
}

